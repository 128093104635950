import React from 'react';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { closestCenter } from '@dnd-kit/core';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Checkbox,
  Button,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox'; // For "Select All"
import ClearIcon from '@mui/icons-material/Clear'; // For "Deselect All"
import { pointerWithin } from '@dnd-kit/core';
import { useDroppable } from '@dnd-kit/core';

function KanbanBoardGeneric({
  statuses = [],
  products = [],
  onDragEnd,
  onIndividualEdit,
  onBulkEdit,
  onDeleteRequest,
  selectedProducts = {},
  onSelectProduct,
  onSelectAll,
  onDeselectAll,
}) {
  // Group products by their status
  const groupedProducts = statuses.reduce((acc, status) => {
    acc[status] = products.filter((product) => product.status === status);
    return acc;
  }, {});

  const statusDisplayNames = {
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    completed: 'Completed',
  };

  const handleDragEndInternal = (event) => {
    const { active, over } = event;
    if (!over) return;

    const productId = parseInt(active.id, 10);
    const newStatus = statuses.find((status) => status === String(over.id));

    // Validate newStatus
    if (!newStatus) {
      console.warn(`No valid target status found for productId ${productId}`);
      return;
    }

    // Check if the dragged product is part of a bulk selection
    const draggedStatus = Object.keys(groupedProducts).find((status) =>
      groupedProducts[status]?.some((p) => p.product_id === productId)
    );

    const productsToMove =
      selectedProducts[draggedStatus]?.length > 0 &&
      selectedProducts[draggedStatus].includes(productId)
        ? selectedProducts[draggedStatus] // Bulk move
        : [productId]; // Individual move

    // Call the appropriate handler
    onDragEnd(productsToMove, newStatus);
  };

  return (
    <DndContext
      collisionDetection={pointerWithin}
      onDragEnd={handleDragEndInternal}
    >
<Box
  sx={{
    display: 'flex',
    gap: 2,
    padding: 2,
    width: '100%',
    flexGrow: 1, // Ensure columns fill available space
    justifyContent: 'space-between', // Evenly distribute space between columns
  }}
>

        {statuses.map((status) => {
          const displayName = statusDisplayNames[status] || status;

          const isAllSelected =
            groupedProducts[status]?.length > 0 &&
            selectedProducts[status]?.length ===
              groupedProducts[status]?.length;
          const hasSelection = selectedProducts[status]?.length > 0;

          return (
            <DroppableContainer key={status} id={status}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {displayName}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {/* Render "Select All" only if the column has entries */}
                  {groupedProducts[status]?.length > 0 && (
                    <IconButton
                      onClick={() => onSelectAll(status)}
                      disabled={
                        selectedProducts[status]?.length ===
                        groupedProducts[status]?.length
                      }
                      aria-label="select all"
                    >
                      <CheckBoxIcon />
                    </IconButton>
                  )}

                  {/* Render "Deselect All" only if the column has entries */}
                  {groupedProducts[status]?.length > 0 && (
                    <IconButton
                      onClick={() => onDeselectAll(status)}
                      disabled={selectedProducts[status]?.length === 0}
                      aria-label="deselect all"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}

                  {/* Bulk Edit */}
                  {/* Render Bulk Edit button only if the status is editable */}
                  {!['notStarted', 'completed'].includes(status) &&
                    groupedProducts[status]?.length > 0 && (
                      <IconButton
                        onClick={() => onBulkEdit(status)}
                        disabled={!hasSelection}
                        aria-label="bulk edit"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                </Box>
              </Box>

              <SortableContext
                items={groupedProducts[status]?.map((product) =>
                  product.product_id.toString()
                )}
                strategy={verticalListSortingStrategy}
              >
                {groupedProducts[status]?.map((product) => (
                  <SortableItem
                    key={product.product_id}
                    product={product}
                    isSelected={selectedProducts[status]?.includes(
                      product.product_id
                    )}
                    onSelect={() => onSelectProduct(status, product.product_id)}
                    onDelete={() => onDeleteRequest(product)}
                    //onEdit={() => onIndividualEdit(product.product_id)}
                    onEdit={() => onIndividualEdit(product)}
                  />
                ))}
              </SortableContext>
            </DroppableContainer>
          );
        })}
      </Box>
    </DndContext>
  );
}

/*
function DroppableContainer({ id, children }) {
  const { isOver, setNodeRef } = useSortable({ id });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        width: 300,
        background: isOver ? '#d0f0d0' : '#f4f4f4',
        minHeight: 200,
        padding: 2,
        borderRadius: 2,
        border: isOver ? '2px solid green' : '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
}
*/

function DroppableContainer({ id, children }) {
  const { isOver, setNodeRef } = useDroppable({ id });

  return (
<Box
  ref={setNodeRef}
  sx={{
    flex: 1, // Allow column to grow evenly
    minWidth: 0, // Prevent overflow due to content
    background: isOver ? '#d0f0d0' : '#f4f4f4',
    minHeight: 200,
    padding: 2,
    borderRadius: 2,
    border: isOver ? '2px solid green' : '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    transition: 'background 0.2s ease, border 0.2s ease',
  }}
>
  {children}
</Box>

  );
}


function SortableItem({ product, isSelected, onSelect, onDelete, onEdit }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: product.product_id.toString(),
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // Disable edit for "not started" or "completed" statuses
  const isEditable = !['notStarted', 'completed'].includes(product.status);

  return (
    <Paper
      ref={setNodeRef}
      style={style}
      sx={{
        padding: 2,
        background: '#ffffff',
        boxShadow: 1,
        display: 'flex',
        alignItems: 'center',
        border: isSelected ? '2px solid blue' : '1px solid #ccc',
      }}
    >
      <Checkbox
        checked={!!isSelected}
        onChange={onSelect}
        inputProps={{ 'aria-label': 'select product' }}
        sx={{ marginRight: 1 }}
      />
      <IconButton
        {...listeners}
        {...attributes}
        size="small"
        sx={{ cursor: 'grab', marginRight: 1 }}
      >
        <DragIndicatorIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {product.product_name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          SKU: {product.product_code}
        </Typography>
      </Box>
      <Box>
        {/* 
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          size="small"
          color="error"
        >
          <DeleteIcon />
        </IconButton>*/}

        {/* Render the Edit button only if the product is editable */}
        {isEditable && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            size="small"
            disabled={!isEditable}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
}

export default KanbanBoardGeneric;
